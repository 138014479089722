import { useEffect } from 'react'
import { AbilityBuilder, createMongoAbility } from '@casl/ability'

import { useAbilityContext } from './useAbilityContext'

import { useAuth } from '@isdd/metais-common/contexts/auth/authContext'
import { useGetRightsForPO } from '@isdd/metais-common/api/generated/iam-swagger'
import { ConfigurationItemUi, useGetRoleParticipant } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { DOCUMENTS, ROLES } from '@isdd/metais-common/constants'
import { getUniqueRules } from '@isdd/metais-common/permissions/helpers'

export enum DocumentsActions {
    HANDLE = 'handle',
    DELETE = 'delete',
}

export const useProjectDocumentsPermissions = (projectData: ConfigurationItemUi | undefined, projectRoles: string[]) => {
    const abilityContext = useAbilityContext()
    const {
        state: { user },
    } = useAuth()
    const { data: roleParticipant } = useGetRoleParticipant(projectData?.metaAttributes?.owner ?? '', { query: { enabled: !!projectData } })
    const { data: rightsForPOData } = useGetRightsForPO(
        { identityUuid: user?.uuid ?? '', cmdbId: roleParticipant?.owner ?? '' },
        { query: { enabled: !!roleParticipant && !!user } },
    )

    useEffect(() => {
        const { can, rules: newRules } = new AbilityBuilder(createMongoAbility)
        const rolesForPO = rightsForPOData?.filter((role) => projectRoles?.find((currentRole) => currentRole === role.roleName))
        if (rolesForPO && rolesForPO.length > 0) {
            can(DocumentsActions.HANDLE, DOCUMENTS)
        }
        if (user?.roles.includes(ROLES.R_ADMIN)) {
            can(DocumentsActions.DELETE, DOCUMENTS)
        }
        const existingRules = abilityContext.rules
        const updatedRules = getUniqueRules(newRules, existingRules)

        const mergedRules = [...existingRules, ...updatedRules]

        abilityContext.update(mergedRules)
    }, [abilityContext, projectRoles, rightsForPOData, user?.roles])

    return {}
}
