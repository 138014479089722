import { Roles } from '@isdd/metais-common/api'

export enum RouteNames {
    HOME = '/',
    DEV_TEST_SCREEN = '/DevTestScreen',
    PROJEKT_LIST_PAGE = '/projekt/list',
    PROJEKT_ENTITY_DETAIL = '/ci/:entityName/:projektId',
    //HOW_TO_PUBLIC_AUTHORITIES = '/howto/PUBLIC_AUTHORITIES_HOWTO',
    HOW_TO_EGOV_COMPONENTS = '/howto/EGOV_HOWTO',
    HOW_TO_STANDARDIZATION = '/howto/STD_HOWTO',
    HOW_TO_CODELIST = '/howto/CODELISTS_HOWTO',
    HOW_TO_MONITORING = '/howto/MONITORING_HOWTO',
    HOW_TO_DATA_OBJECTS_MAIN = '/howto/DATA_OBJECTS',
    REGISTER_DATA_RIGHTS_OBLIGATIONS = '/howto/REGISTER_DATA_RIGHTS_OBLIGATIONS',
    //HOW_TO_PO = '/howto/PO_HOWTO',
    HOW_TO_KRIS_STUDIES_PROJECTS = '/howto/SPK_HOWTO',
    HOW_TO_REFERENCE_IDENTIFIERS = '/howto/URI_HOWTO',
    // HOW_TO_PROGRAMS_PROJECTS_ACTIVITIES = '/howto/PROGRAMS_PROJECTS_ACTIVITIES',
    HOW_TO_DATA_OBJECTS = '/howto/REF_REG_HOWTO', // the same as HOW_TO_REFERENCE_REGISTERS = '/howto/REF_REG_HOWTO'
    ISVS_INTEGRATIONS_HOWTO = '/howto/ISVS_INTEGRATIONS_HOWTO',
    PREHLADY_A_POSTUPY = '/howto/METAIS_HELP',
    DOCUMENTS_LIST_TAB = '/ci/:entityName/:entityId/documents',
    RELATIONSHIPS_LIST_TAB = '/ci/:entityName/:entityId/relationships',
    TASKS = '/ulohy',
    USER_PROFILE = '/userprofile',
    REPORTS = '/reports',
    REFERENCE_REGISTERS = '/refregisters',
    GLOBAL_SEARCH = '/global/search',
    MEDIA_WIKI = '/media-wiki',
    CONTACT = '/contact',
    TRAININGS = '/trainings',
    AUTHORIZATIONS_AND_OBLIGATIONS_DATA_REGISTER = '/howto/obligations',
}

export enum RouterRoutes {
    HOME = '/',
    AUTH_SUCCESS = '/auth-success',
    IDENTITY_TERMS = '/IdentityTerms',
    CI_DETAIL = '/ci/:entityName/:entityId',
    CI_CREATE = '/ci/:entityName/create',
    CI_EDIT = '/ci/:entityName/:entityId/edit',
    CI_AS_CLONE = '/ci/AS/:entityId/clone',
    CI_CREATE_ITEM_RELATION = 'ci/:entityName/:entityId/new-ci/:tabName',
    CI_CREATE_PROJEKT_RELATION = 'ci/:entityName/:entityId/new-ci/Projekt',
    CI_CREATE_RELATION = 'ci/:entityName/:entityId/new-relation/:tabName',
    DEV_TEST_SCREEN = '/DevTestScreen',
    CI_LIST = '/ci/:entityName',
    DOCUMENTS_OUTLET = 'documents',
    RELATIONSHIPS_OUTLET = 'relationships',
    ACTIVITIES_OUTLET = 'activities',
    GOALS_OUTLET = 'goals',
    TASKS_OUTLET = 'tasks',
    EVALUATION_OUTLET = 'evaluation',
    HISTORY_OUTLET = 'history',
    WEB_DECLARATION = 'declaration',
    FINANCES = 'finances',
    HISTORY_CHANGES_OUTLET = 'historyChanges',
    HISTORY_COMPARE_SINGLE_ITEM = '/ci/:entityName/:entityId/history/:firstId',
    HISTORY_COMPARE_TWO_ITEMS = '/ci/:entityName/:entityId/history/:firstId/:secondId',
    USER_PROFILE = '/userprofile',
    TASK_DETAIL = '/ulohy/:taskId',
    TASKS = '/ulohy',
    STANDARDIZATION_VOTE_LIST = '/standardization/voteslist',
    STANDARDIZATION_VOTE_DETAIL = '/standardization/voteslist/:voteId',
    STANDARDIZATION_VOTE_DETAIL_EXTERNAL = '/standardization/voteslist/:voteId/vote',
    STANDARDIZATION_VOTE_DETAIL_EXTERNAL_VETO = '/standardization/voteslist/:voteId/veto',
    STANDARDIZATION_VOTE_EDIT = '/standardization/voteslist/:voteId/edit',
    STANDARDIZATION_VOTE_CREATE = '/standardization/voteslist/create',
    STANDARDIZATION_MEETINGS_LIST = '/standardization/meetingslist',
    STANDARDIZATION_MEETINGS_DETAIL = '/standardization/meetingslist/:meetingId',
    STANDARDIZATION_MEETINGS_DETAIL_PARTICIPATE = '/standardization/meetingslist/:meetingId/participate',
    STANDARDIZATION_MEETINGS_EDIT = '/standardization/meetingslist/:meetingId/edit',
    STANDARDIZATION_MEETINGS_CREATE = '/standardization/meetingslist/create',
    STANDARDIZATION_GROUPS_LIST = '/standardization/groupslist',
    STANDARDIZATION_GROUPS_DETAIL_ITVS = '/standardization/groupslist/itvs',
    STANDARDIZATION_GROUPS_DETAIL = '/standardization/groupslist/:groupId',
    STANDARDIZATION_GROUPS_EDIT_ITVS = '/standardization/groupslist/itvs/edit',
    STANDARDIZATION_GROUPS_EDIT = '/standardization/groupslist/:groupId/edit',
    STANDARDIZATION_GROUPS_CREATE = '/standardization/groupslist/create',
    STANDARDIZATION_DRAFTS_LIST = '/standardization/draftslist',
    STANDARDIZATION_DRAFTS_DETAIL = '/standardization/draftslist/:entityId',
    STANDARDIZATION_DRAFTS_EDIT = '/standardization/draftslist/:entityId/edit',
    STANDARDIZATION_DRAFTS_CREATE = '/standardization/draftslist/create',
    REPORTS_LIST = '/reports',
    IMPORT_MONITORING_PARAMETERS = '/monitoring/import',
    MONITORING_INSERT = '/monitoring/services/monitoras/insert',
    MONITORING_DETAIL = '/monitoring/services/monitoras',
    MONITORING_SERVICES = '/monitoring/services',
    REPORTS_DETAIL = '/reports/:entityId',
    REPORTS_CREATE = '/reports/create',
    RELATIONS = '/relation/:entityName/:entityId/:relationshipId',
    REGISTRATION = '/registration',
    REGISTRATION_SUCCESS = '/registration/success',
    REGISTRATION_FAILED = '/registration/failed',
    PUBLIC_SPACE = '/publicspace',
    PUBLIC_AUTHORITIES_HIERARCHY = '/public-authorities-hierarchy',
    NOTIFICATIONS = '/notifications',
    NOTIFICATIONS_DETAIL = '/notifications/:id',
    HOW_TO_GENERAL_PAGE = '/howto/*',
    HOW_TO_GENERIC_PAGE = '/howto/:howToEnumType',
    HOW_TO_MONITORING_PAGE = '/howto/MONITORING_HOWTO',
    HELP = '/help',
    GLOBAL_SEARCH = '/global/search',
    DATA_OBJECT_CODE_LIST = 'data-objects/codelists',
    DATA_OBJECT_CODE_LIST_DETAIL = 'data-objects/codelists/:id',
    DATA_OBJECT_CODE_LIST_EDIT = 'data-objects/codelists/:id/edit',
    DATA_OBJECT_CODE_LIST_HIERARCHY = 'data-objects/hierarchy',
    DATA_OBJECT_REQUESTS_LIST = 'data-objects/requestlist',
    DATA_OBJECT_REQUESTS_DETAIL = 'data-objects/requestlist/:requestId',
    DATA_OBJECT_REQUESTS_EDIT = 'data-objects/requestlist/:requestId/edit',
    DATA_OBJECT_REQUESTS_CREATE = '/data-objects/requestlist/create',
    DATA_OBJECT_REF_IDENTIFIERS_LIST = '/data-objects/ref-identifiers-list',
    DATA_OBJECT_REF_IDENTIFIER_REQUESTS = '/data-objects/ref-identifiers',
    DATA_OBJECT_REF_IDENTIFIER_REQUESTS_DETAIL = '/data-objects/ref-identifiers/:id',
    DATA_OBJECT_REF_IDENTIFIER_REQUESTS_EDIT = '/data-objects/ref-identifiers/:id/edit',
    DATA_OBJECT_REF_IDENTIFIERS_CREATE = '/data-objects/ref-identifiers/create',
    DATA_OBJECT_REF_IDENTIFIERS_DETAIL = '/data-objects/ref-identifiers-list/:id',
    DATA_OBJECT_REF_IDENTIFIERS_EDIT = '/data-objects/ref-identifiers-list/:id/edit',
    REF_REGISTERS_LIST = '/refregisters',
    REF_REGISTERS_DETAIL = '/refregisters/:entityId',
    REF_REGISTERS_EDIT = '/refregisters/:entityId/edit',
    REF_REGISTERS_CREATE = '/refregisters/create',
    REF_REGISTERS_HISTORY_COMPARE_SINGLE_ITEM = '/refregisters/:entityId/history/:firstId',
    REF_REGISTERS_HISTORY_COMPARE_TWO_ITEMS = '/refregisters/:entityId/history/:firstId/:secondId',
    CI_PROJECT_DETAIL = 'ci/Projekt/:entityId',
    CI_PROJECT_CREATE = 'ci/Projekt/create',
    CI_PROJECT_EDIT = 'ci/Projekt/:entityId/edit',
    CI_ISVS_DETAIL = 'ci/ISVS/:entityId',
    CI_ISVS_CREATE = 'ci/ISVS/create',
    CI_PO_PO = 'ci/PO_PO',
    CI_PO_IS = 'ci/PO_IS',
    CI_PO_IS_PO = 'ci/PO_IS_PO',
    CI_PO_PO_DETAIL = 'ci/PO_PO/:entityId',
    CI_PO_IS_DETAIL = 'ci/PO_IS/:entityId',
    CI_PO_IS_PO_DETAIL = 'ci/PO_IS_PO/:entityId',
    CI_TRAINING = 'ci/Training',
    CI_TRAINING_DETAIL = 'ci/Training/:entityId',
    CI_TRAINING_INVITE = 'ci/Training/:entityId/invite',
    CI_TRAINING_CREATE = 'ci/Training/create',
    CI_TRAINING_EDIT = 'ci/Training/:entityId/edit',
    CI_ZC = 'ci/ZC',
    CI_ZC_DETAIL = 'ci/ZC/:entityId',
    CI_PRINCIPLE_DETAIL = 'ci/Princip/:entityId',
    CI_PRINCIPLE_CREATE = 'ci/Princip/create',
    CI_KRIS_LIST = 'ci/KRIS',
    CI_KRIS_DETAIL = 'ci/Kris/:entityId',
    CI_KRIS_CREATE = 'ci/Kris/create',
    CI_KS_DETAIL = 'ci/KS/:entityId',
    CI_KS_CREATE = 'ci/KS/create',
    CI_FORMULAR_CREATE = 'ci/Formular/create',
    CI_FORMULAR_EDIT = 'ci/Formular/:entityId/edit',
    CI_AS_DETAIL = 'ci/AS/:entityId',
    CI_AS_CREATE = 'ci/AS/create',
    CI_REFERENCE_REGISTER = 'ci/ReferenceRegister/:entityId',
    CI_PLACE_OF_OPERATION = 'ci/MiestoPrevadzky',
    CI_PLACE_OF_OPERATION_DETAIL = 'ci/MiestoPrevadzky/:entityId',
    ITVS_EXCEPTIONS_LIST = 'ci/OsobitnyPostup',
    ITVS_EXCEPTIONS_CREATE = 'ci/OsobitnyPostup/create',
    ITVS_EXCEPTIONS_DETAIL = 'ci/OsobitnyPostup/:entityId',
    ITVS_EXCEPTIONS_EDIT = 'ci/OsobitnyPostup/:entityId/edit',
    INTEGRATION_LIST = '/ci/Integracia',
    INTEGRATION_CREATE = '/ci/Integracia/create',
    INTEGRATION_EDIT = '/ci/Integracia/:entityId/edit',
    INTEGRATION_DETAIL = '/ci/Integracia/:entityId',
    INTEGRATION_HISTORY = '/ci/Integracia/:entityId/history',
    INTEGRATION_KS_AS_OUTLET = 'ksAsList',
    INTEGRATION_SUBJECTS_LIST_OUTLET = 'subjectsList',
    INTEGRATION_HARMONOGRAM_OUTLET = 'harmonogram',
    RELATION_LIST = 'relations',
    SLA_CONTRACT_LIST = '/ci/Kontrakt',
    SLA_CONTRACT_DETAIL = '/ci/Kontrakt/:entityId',
    SLA_CONTRACT_HISTORY = '/ci/Kontrakt/:entityId/history',
    SLA_CONTRACT_SUPPORT_CONTACT = '/ci/Kontrakt/:entityId/support-contact',
    SLA_CONTRACT_CREATE = '/ci/Kontrakt/create',
    SLA_CONTRACT_EDIT = '/ci/Kontrakt/:entityId/edit',
    KS_PUBLISH_PAGE = '/ci/KS/publish',

    ATRIBUT_OBJEKTU_EVIDENCIE_CREATE = '/ci/AtributObjektuEvidencie/create',
    ATRIBUT_OBJEKTU_EVIDENCIE_EDIT = '/ci/AtributObjektuEvidencie/:entityId/edit',
    /* to remove */
    /*
    OLA_CONTRACT_LIST = '/ola-contract-list',
    OLA_CONTRACT_ADD = '/ola-contract-add',
    OLA_CONTRACT_DETAIL = '/ola-contract-list/:entityId',
    OLA_CONTRACT_EDIT_OLD = '/ola-contract-list/:entityId/edit',
    */

    OLA_CONTRACT_LIST = '/ci/OLA_Kontrakt',
    OLA_CONTRACT_DETAIL = '/ci/OLA_Kontrakt/:entityId',
    OLA_CONTRACT_CREATE = '/ci/OLA_Kontrakt/create',
    OLA_CONTRACT_EDIT = '/ci/OLA_Kontrakt/:entityId/edit',
    BULK_ACTION_ITEM_LIST = '/bulk-list',
}

export enum AdminRouteNames {
    HOME = '/',
    DASHBOARD = '/dashboard',
    ASSIGNMENT = '/assignment',
    NOTIFICATIONS = '/notifications',
    SETTINGS = '/userprofile',
    EKO = '/eko',
    USER_MANAGEMENT = '/managementList',
    REQUEST_LIST_ALL = '/managementList/requestListAll',
    REQUEST_DETAIL = '/managementList/requestListAll/detail/requests',
    REGISTRATION_DETAIL = '/managementList/requestListAll/detail/registration',
    GDPR_DETAIL = '/managementList/requestListAll/detail/gdpr',
    EGOV = '/egov',
    EGOV_ENTITY = '/egov/entity',
    EGOV_PROFILE = '/egov/profile',
    EGOV_RELATION = '/egov/relation',
    TOOLTIPS = '/tooltips',
    ROLE_USERS = '/egov/roles/users',
    ROLE_EDIT = '/egov/roles/edit',
    ROLE_NEW = '/egov/roles/create',
    ROLES = '/egov/roles',
    PUBLIC_AUTHORITIES = '/public-authorities',
    PUBLIC_AUTHORITIES_LIST = '/public-authorities',
    PUBLIC_AUTHORITIES_FIND = '/public-authorities/find',
    PUBLIC_AUTHORITIES_CREATE = '/public-authorities/:entityId/:ico/create',
    PUBLIC_AUTHORITIES_MASS_UPDATE = '/public-authorities/mass-update',
    CODELISTS = '/codelists-management',
    MONITORING = '/monitoring',
    MONITORING_PARAMS = '/monitoring/params',
    MONITORING_LIST = '/monitoring/list',
    MONITORING_CREATE = '/monitoring/create',
    MONITORING_EDIT = '/monitoring/edit',
    MONITORING_DETAIL = '/monitoring/detail',
    REPORTS_MANAGEMENT = '/reports-management',
    PROJECTS_MANAGEMENT = '/projects',
    PROJECTS_FINANCE_MANAGEMENT = '/projects/finance-management',
    DOCUMENTS_MANAGEMENT = '/projects/documents',
    TEMPLATES_MANAGEMENT = '/templates-management',
    SYSTEM_STATUS_SETTINGS = '/system-status-settings',
    LOCALIZATION = '/localization',
}

export enum NavigationSubRoutes {
    HOW_TO_REFERENCE_REGISTERS = '/howto/REF_REG_HOWTO',
    TASK_DETAIL = '/ulohy/:taskId',
    PROCESSORS_OF_IT_DEVELOPMENT_CONCEPTS = '/ci/PO_IS',
    KRIT = '/ci/KRIS',
    SUBORDINATES = '/ci/PO_PO',
    NOT_OVM = '/ci/PO_IS_PO',
    PRINCIP = '/ci/Princip',
    CIEL = '/ci/Ciel',
    AKTIVITA = '/ci/Aktivita',
    PROGRAM = '/ci/Program',
    PROJEKT = '/ci/Projekt',
    KONCOVE_SLUZBY = '/ci/KS',
    APLIKACNE_SLUZBY = '/ci/AS',
    PROCES = '/ci/BP',
    ISVS = '/ci/ISVS',
    INFRASCTRUCTURES = '/ci/InfraSluzba',
    AUTORITY = '/ci/PO',
    TRAINING = '/ci/Training',
    ZC = '/ci/ZC',
    OSOBITNY_POSTUP = '/ci/OsobitnyPostup',
    OBJEKTY_EVIDENCIE = '/ci/ObjektEvidencie',
    ATRIBUTY_OBJEKTY_EVIDENCIE = '/ci/AtributObjektuEvidencie',
    OBJEKTY_EVIDENCIE_DETAIL = '/ci/ObjektEvidencie/:entityId',
    ATRIBUTY_OBJEKTY_EVIDENCIE_DETAIL = '/ci/AtributObjektuEvidencie/:entityId',
    FORMULAR = '/ci/Formular',
    ATTACHMENT_TYPE = '/ci/Priloha_typ',
    OBJEKTY_EVIDENCIE_CREATE = '/ci/ObjektEvidencie/create',
    OBJEKTY_EVIDENCIE_EDIT = '/ci/ObjektEvidencie/:entityId/edit',
    STANDARDY_ISVS = '/howto/STD_HELP',
    OSOBITNE_POSTUPY = '/ci/osobitny_postup_ITVS',
    PRACOVNE_SKUPINY_KOMISIE = '/standardization/groupslist',
    PRACOVNA_SKUPINA_DETAIL = '/standardization/groupslist',
    PRACOVNA_SKUPINA_DETAIL_ITVS = '/standardization/groupslist/itvs',
    PRACOVNA_SKUPINA_CREATE = '/standardization/groupslist/create',
    PRACOVNA_SKUPINA_EDIT = '/standardization/groupslist',
    ZOZNAM_NAVRHOV = '/standardization/draftslist',
    ZOZNAM_HLASOV = '/standardization/voteslist',
    ZOZNAM_HLASOV_DETAIL = '/standardization/voteslist',
    ZOZNAM_HLASOV_EDIT = '/standardization/voteslist',
    ZOZNAM_HLASOV_CREATE = '/standardization/voteslist/create',
    ZOZNAM_ZASADNUTI = '/standardization/meetingslist',
    ZOZNAM_ZASADNUTI_CREATE = '/standardization/meetingslist/create',
    ZOZNAM_ZASADNUTI_DETAIL = '/standardization/meetingslist',
    TVORBA_NAVRHU = '/standardization/newdraft',
    REFERENCE_REGISTER = '/refregisters',
    CODELIST = '/data-objects/codelists',
    REF_URI_HOWTO = '/howto/URI_HOWTO',
    REF_IDENTIFIERS_LIST = '/data-objects/ref-identifiers-list',
    REF_IDENTIFIERS = '/data-objects/ref-identifiers',
    REQUESTLIST = '/data-objects/requestlist',
    CODELIST_HIERARCHY = '/data-objects/hierarchy',
    CENTRALNY_DATOVY_MODEL = '/todo',
    PRUD = '/todo',
    JEDNOTNA_DIGITALNA_BRANA = '/todo',
    MONITORING_KS = '/monitorks',
    MONITORING_AS = '/monitoras',
    MONITORING_PARAMETRE_SET = '/monitor-report',
    MONITORING_IMPORT = '/todo',
    NOTIFICATIONS = '/notifications',
    LICENCIE = '/howto/LICENSE_HOWTO',
    SERVERY = '/servers',
    WEBOVE_SIDLO = '/ci/WeboveSidlo',
    VIRTUALNE_STROJE = '/virtual-machines',
    PUBLIC_AUTHORITY_HIERARCHY = '/public-authorities-hierarchy',
    REFERENCE_REGISTERS_REQUESTS = '/refregisters/requests',
    IDENTIFIERS_REQUESTS = '/uri/list/sent',
    IDENTIFIERS_LIST = '/uri/list/accepted',
    EDUCATIONAL_COURSES = '/courses',
    RELATIONS_LIST = '/relations',
    TOTAL_COST_OF_OWNERSHIP = '/howto/TOTAL_OWNERSHIP_COSTS_HOWTO',
    INTEGRATION_AGREEMENTS_ON_SERVICE_LEVEL = '/isla-contract-list',
    ISLA = '/ci/Kontrakt',
    INTEGRATION_LINKS = '/ci/Integracia',
    HOW_TO_DATA_OBJECTS = 'HOW_TO_DATA_OBJECTS',
}

export enum AdminRouteRoles {
    ADMIN = 'R_ADMIN',
    HKO = 'R_HKO',
    METAIS = 'R_METAIS',
    LM = 'LIC_LM_UPVII',
    GDPR = 'GDPR',
    STD_KA = 'STD_KOORDINATOR_AGENDY',
}
export interface NavigationSubItem {
    title: string
    path: string
    target?: string
    isLoginRequired?: boolean
    requiredRole?: Roles
    subItems?: NavigationSubItem[]
    role?: AdminRouteRoles[]
    customPathMatcher?: string
}

export interface NavigationItem {
    title: string
    path: string
    icon?: string
    isLoginRequired?: boolean
    requiredRole?: Roles
    subItems?: NavigationSubItem[]
    role?: AdminRouteRoles[]
    hideHelp?: boolean
    customPathMatcher?: string
}

export enum FooterRouteNames {
    ACCESSIBILITY_DECLARATION = '/technical/declaration',
    WEB_RESIDENCY_MAP = '/sitemap',
    COOKIES = '/cookies/info',
    PERSONAL_DATA_PROTECTION = '/cookies/personalDataProtection',
    TERMS_OF_USE = '/cookies/termsOfUse',
    COOKIES_SETTINGS = '/cookies/settings',
    TECHNICAL_OPERATOR = '/technical-operator',
    CONTENT_ADMIN = '/spravca-obsahu',
    RSS = '/rss',
    IDSK_DIZAJN = 'https://idsk.gov.sk',
    EXCEPTIONS_LEGISLATION = '/ci/OsobitnyPostup',
    ABOUT_APPLICATION = '/about-application',
    SITEMAP = '/sitemap',
    OPENDATA = '/opendata',
}

export enum RegistrationRoutes {
    REGISTRATION = '/registration',
    REGISTRATION_SUCCESS = '/registration/success',
    REGISTRATION_FAILED = '/registration/failed',
}

export const LoginRouteNames = {
    LOGIN_PROBLEMS: '/login-problems',
    FORGOTTEN_PASSWORD: '/forgotten-password',
}

export const SLARouteNames = {
    SLADetail: '/sla-detail/:entityName/:paramType/:serviceId/:slaId',
    SLAParamsList: '/sla-params-list/:entityName',
}
