import React, { Reducer, createContext, useContext, useReducer, useEffect } from 'react'
import { IAuthContext, AuthContext, TAuthConfig } from 'react-oauth2-code-pkce'
import { useSearchParams } from 'react-router-dom'

import { PRE_LOGIN_PATH } from './authConfig'
import { silentLogin, generateCodeChallenge, generateRandomString } from './auth.utils'

export interface Role {
    gid: string
    roleDescription: string
    roleName: string
    roleUuid: string
    roleWeight: number
}

export interface Group {
    orgId: string
    roles: Role[]
}

export interface User {
    login: string
    displayName: string
    firstName: string
    lastName: string
    state: string
    position: string
    uuid: string
    name: string
    preferred_username: string
    authenticationResource: string
    email: string
    phone: string
    roles: string[]
    groupData: Group[]
}

export interface ICustomAuthContext {
    user: User | null
    token: string | null
    identityTermsAccepted?: boolean
    isTokenLoaded: boolean
    isUserLoaded: boolean
}

const initialState: ICustomAuthContext = {
    user: null,
    token: null,
    identityTermsAccepted: undefined,
    isTokenLoaded: false,
    isUserLoaded: false,
}

export enum CustomAuthActions {
    SET_USER_INFO,
    SET_USER_TOKEN,
    SET_IDENTITY_TERMS,
    LOGOUT_USER,
}

interface SetUserInfo {
    type: CustomAuthActions.SET_USER_INFO
    value: User
}

interface SetUserToken {
    type: CustomAuthActions.SET_USER_TOKEN
    token: string
}

interface LogoutUser {
    type: CustomAuthActions.LOGOUT_USER
}

interface SetIdentityTerms {
    type: CustomAuthActions.SET_IDENTITY_TERMS
    identityTerms: boolean
}

type Action = SetUserInfo | SetUserToken | LogoutUser | SetIdentityTerms

const reducer = (state: ICustomAuthContext, action: Action): ICustomAuthContext => {
    switch (action.type) {
        case CustomAuthActions.SET_USER_INFO:
            return { ...state, user: action.value, isUserLoaded: true }
        case CustomAuthActions.SET_USER_TOKEN:
            return { ...state, token: action.token, isTokenLoaded: true }
        case CustomAuthActions.SET_IDENTITY_TERMS:
            return { ...state, identityTermsAccepted: action.identityTerms }
        case CustomAuthActions.LOGOUT_USER:
            return { ...initialState, isUserLoaded: true, isTokenLoaded: true }
        default:
            return state
    }
}

const CustomAuthContext = createContext<{ state: ICustomAuthContext; dispatch: React.Dispatch<Action> }>({
    state: initialState,
    dispatch: () => null,
})
let loginInProgress = false
const AuthContextProvider: React.FC<React.PropsWithChildren & { authConfig: TAuthConfig }> = (props) => {
    const authContext = useContext<IAuthContext>(AuthContext)
    const [state, dispatch] = useReducer<Reducer<ICustomAuthContext, Action>>(reducer, { ...initialState, token: authContext.token })
    const [uriParams] = useSearchParams()

    useEffect(() => {
        if (authContext.token) {
            dispatch({ type: CustomAuthActions.SET_USER_TOKEN, token: authContext.token })
            sessionStorage.removeItem('ROCP_idToken')
            sessionStorage.removeItem('ROCP_token')
            sessionStorage.removeItem('ROCP_auth_state')
        } else if (state.token || state.user) {
            dispatch({ type: CustomAuthActions.LOGOUT_USER })
            //&& sessionStorage.getItem('ROCP_loginInProgress') !== 'true'
        } else if (!uriParams.get('code') && !loginInProgress) {
            loginInProgress = true
            const codeVerifier = generateRandomString(96)
            localStorage.setItem(PRE_LOGIN_PATH, window.location.pathname)

            sessionStorage.setItem('PKCE_code_verifier', codeVerifier)
            generateCodeChallenge(codeVerifier).then((codeChallange) => {
                silentLogin(codeChallange, props.authConfig)
                    .then((res) => {
                        if (res.url && res.url.includes('code=')) {
                            location.replace(res.url)
                            sessionStorage.setItem('ROCP_loginInProgress', 'true')
                        }
                    })
                    .catch(() => {
                        dispatch({ type: CustomAuthActions.LOGOUT_USER })
                    })
            })
        } else if (!authContext.loginInProgress && !loginInProgress) {
            dispatch({ type: CustomAuthActions.LOGOUT_USER })
        }
    }, [authContext, props.authConfig, state.token, state.user, uriParams])

    return <CustomAuthContext.Provider value={{ state, dispatch }}>{props.children}</CustomAuthContext.Provider>
}

const useAuth = () => useContext(CustomAuthContext)

export { useAuth, AuthContextProvider }
